import React, { Component } from "react";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBTable,
} from "mdbreact";
import { Link } from "react-router-dom";
import "moment/locale/id.js";
import CurrencyFormat from "react-currency-format";
import { isEmpty, map } from "lodash";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Logo from "../assets/images/logo-YPL.png";
import { trackPromise } from "react-promise-tracker";
import { CopyToClipboard } from "react-copy-to-clipboard";

const MySwal = withReactContent(Swal);

class Bayar_pendaftaran_detail extends Component {
  constructor(props) {
    super(props);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onChecked = this._onChecked.bind(this);
    this._onUnchecked = this._onUnchecked.bind(this);
    this._onUncheckedAll = this._onUncheckedAll.bind(this);
    this.onBayar = this.onBayar.bind(this);
    this.detailPayments = this.detailPayments.bind(this);
    this.cancelPayments = this.cancelPayments.bind(this);
    this.refreshPayments = this.refreshPayments.bind(this);

    this.state = {
      student_id: "",
      is_show: false,
      image_link: null,
      note_return: null,
      nama_bank: JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_unit.bank_name,
      nama_rek: JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_unit.account_name,
      no_rekening: JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_unit.account_number,
      uang_pendaftaran: JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_unit.registration_fee,
      organizations_id: JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_unit.organizations_id,

      form: {
        value: {
          detail_checked: [],
          subtotal: 0,
          total: 0,
          no_va: localStorage.getItem("DATA_JALUR").split(" -")[0],
          name: localStorage.getItem("DATA_JALUR").split("-")[1],
          last_name: "",
          phone: "",
          email: "",
          pg_code: "",
          bank_name: "",
          admin_fee: 0,
          cek_payment: "",
        },
        error: {
          detail_checked: "",
        },
      },
      filters: {
        jenis: JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_unit.payment_type,
      },
      listinvoice: [],
      payload: [],
      param: {
        prm_jenis: [
          { label: "Payment Gateway", value: "2" },
          { label: "Upload Dokumen", value: "1" },
        ],
      },
      note: "",
      encrypt_64: "",
      original_name: "",
    };
  }

  componentDidMount() {
    const { filters } = this.state;
    this.setState({
      student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
      note: JSON.parse(localStorage.getItem("STUDENT_DETAIL")).note_bukti_transfer !== "" ? JSON.parse(localStorage.getItem("STUDENT_DETAIL")).note_bukti_transfer : "",
      image_link: JSON.parse(localStorage.getItem("STUDENT_DETAIL")).image_link_bukti_transfer,
      note_return: JSON.parse(localStorage.getItem("STUDENT_DETAIL")).note_return
    });
    if (filters.jenis == 2) {
      this._onSearchContent();
    }
  }

  async _onSearchContent(params = {}) {
    await this._onUncheckedAll();
    const { form, organizations_id } = this.state;
    const { value } = form;

    let payload = {}

    if (organizations_id == 3) {
      payload = global.variable.LINK_API_AKADEMIS + "create-invoices/listRegisFee";
    }
    else {
      payload = global.variable.LINK_API_AKADEMIS + "create-invoices-general/listRegisFee";
    }
    await trackPromise(
      fetch(
        payload,
        {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            temps_id: localStorage.getItem("DATA_JALUR").split(" -")[0],
          }),
        }
      )
        .then((response) => response.json())
        .then((resData) => {
          if (!isEmpty(resData)) {
            this.setState({ listinvoice: resData, is_show: true });
            this._onChecked(resData[0]);
          } else {
            global.swal("Error", "Invoice tidak ditemukan.", "info")
              .then((value) => {
                this.handleBack();
              });
          }
        })
        .catch((err) => {
          if (err) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          } else {
            global.swal.stopLoading();
            global.swal.close();
          }
        })
    );

    let payload2 = {}

    if (organizations_id == 3) {
      payload2 = global.variable.LINK_API_AKADEMIS + "payments/list-payment-gateway";
    }
    else {
      payload2 = global.variable.LINK_API_AKADEMIS + "payments-general/list-payment-gateway";
    }
    fetch(payload2, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      body: JSON.stringify({ organizations_id: organizations_id })
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData) {
          this.setState({ listbank: resData });
        } else {
          this.setState({ is_show: false });
        }
      })
      .catch((err) => {
        if (err) {
          this.setState({ is_show: false });
          global.swal("Error", "Koneksi ke server gagal.", "info");
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  }

  createSelectItems() {
    let items = [];
    for (let i = 0; i < this.state.param.prm_jenis.length; i++) {
      items.push(
        <option key={i} value={this.state.param.prm_jenis[i].value}>
          {this.state.param.prm_jenis[i].label}
        </option>
      );
    }
    return items;
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  onChangeHandler = event => {
    var file = event.target.files[0];

    if (file.size > 2000000) {
      global.swal("Error", "File tidak boleh lebih dari 2 MB", "info")
      this.setState({ selectedFile: '', original_name: '', })
    } else if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "application/pdf") {
      this.setState({ selectedFile: file, original_name: file.name });
    } else {
      global.swal("Error", "Harap pilih gambar berupa (*.jpg atau *.png) atau pdf", "info")
      this.setState({ selectedFile: '', original_name: '', })
    }

  }

  onDropdownSelected(e) {
    const { target } = e;
    const { value, name } = target;

    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      })
      // () => this._onSearchContent()
    );
    const data = {
      filters: {
        jenis: e.target.value,
      },
      temps_id: this.state.student_id,
    };
  }

  async _onFormChange(event) {
    const { form } = this.state;
    const { cek_payment } = form.value;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
      arrayList,
      paymentId,
    } = dataset;

    // if (fieldName === "listinvoice" && checked == true) {
    //   await this._onUncheckedAll();
    // }
    if (name === "pg_code") {
      var admin_fee = Number(event.target.getAttribute("admin_fee"));
      var admin_percent = Number(event.target.getAttribute("admin_percent"));
      var bank_name = event.target.getAttribute("bank_name");
      if (admin_fee == 0 && admin_percent != 0) {
        admin_fee = Math.round(form.value.subtotal * (admin_percent / 100));
      }
      this.setState((prevState) => ({
        form: {
          value: {
            ...prevState.form.value,
            admin_fee: admin_fee,
            bank_name: bank_name,
          },
          error: {
            ...prevState.form.error,
          },
        },
      }));
    }
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        // if (inputArray) {
        //   newList = prevState.form.value[fieldName];
        //   newListError = prevState.form.error[fieldName];
        //   newList[arrayPosition][name] = formattedValue;
        //   if (!isEmpty(newListError[arrayPosition])) {
        //     newListError[arrayPosition][name] = "";
        //   }
        // }
        // if (type === "checkbox") {
        //   formattedValue = checked ? "true" : "";
        //   if (formattedValue == "true") {
        //     for (let i = 0; i <= arrayPosition; i++) {
        //       this._onChecked(prevState[fieldName][i]);
        //     }
        //   } else {
        //     this._onUnchecked(arrayPosition);
        //   }
        // }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => { }
    );
  }

  _onChecked(data) {
    const { form } = this.state;
    const { detail_checked } = form.value;

    detail_checked.push({
      temps_id: data.temps_id,
      invoices_id: data.invoices_id,
      nominal: data.nominal,
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          detail_checked,
        },
      },
    }));
  }

  _onUnchecked(idx) {
    this.setState((prevState) => {
      const list = prevState.form.value.detail_checked;
      list.splice(idx, list.length);
      return {
        ...prevState,
        detail_checked: list,
      };
    });
  }

  _onUncheckedAll() {
    this.setState((prevState) => {
      const list = prevState.form.value.detail_checked;
      list.splice(0, list.length);
      return {
        ...prevState,
        detail_checked: list,
      };
    });
  }

  download = (param) => (e) => {
    e.preventDefault();
    // console.log(param);
    window.open(
      // "/image/" + param,
      param,
      "_blank",
      "location=yes,height=600,width=600,scrollbars=yes,status=yes"
    );

    //var newTab = window.open(param, "_blank", 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    //newTab.document.body.innerHTML = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==" width="100px" height="100px">';
  };

  // fungsi setelah bank di pilih
  async onBayar(e) {
    e.preventDefault();
    this.openModalRincian();
  }

  fileUploadHandler = event => {
    event.preventDefault();
    const { data } = this.state;

    if (this.state.original_name === '') {
      global.swal("Error", "Harap pilih file gambar", "info")
    } else {
      global.swal({
        text: 'Apakah anda yakin akan disubmit?',
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: [
          'Cancel',
          {
            text: 'Submit',
            closeModal: false,
          }
        ],
      })
        .then((confirm) => {
          let errorMessage = { code: 403, message: 'cancel' };
          if (!confirm) throw errorMessage;
          const file_upload = new FormData();
          file_upload.append("download_document", this.state.selectedFile);
          file_upload.append("name_file", this.state.original_name);

          return fetch(global.variable.LINK_API + "settings/param/uploadFile", {
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + global.cookies.get("access_token"),
            },
            body: file_upload,
          })
            .then(this.handleErrors)
            .then((response) => response.json())
            .then((resData) => {
              if (resData.message === "Success") {
                const data = [];
                let items = {};

                items = Object.create({});
                items.field = "note_bukti_transfer";
                items.value = this.state.note;
                data.push(items);

                items = Object.create({});
                items.field = "image_link_bukti_transfer";
                items.value = resData.link;
                data.push(items);

                const form_data = new FormData()
                form_data.append('registration_id', this.state.student_id);
                form_data.append('action', 'next');
                form_data.append('data', JSON.stringify(data));

                return fetch(global.variable.LINK_API + 'flow/update',
                  {
                    method: 'post',
                    headers: {
                      Accept: "application/json",
                      'Authorization': 'Bearer ' + global.cookies.get('access_token'),
                    },
                    body: form_data,
                  })
                  .then(this.handleErrors)
                  .then((response) => response.json())
                  .then((resData) => {
                    if (resData.message === "Success") {
                      global
                        .swal("Success", "Silahkan menunggu proses verifikasi dan validasi dokumen anda dalam waktu maksimal 24 jam", "success")
                        .then((value) => {
                          this.props.history.push("/bayarpendaftaran");
                        });
                    }
                    // else {
                    //   global.swal(resData.error);
                    // }
                  })
                  .catch(err => {
                    if (err) {
                      if (err.code !== 403) {
                        let message;
                        if ('errors' in err) {
                          if (Array.isArray(err.errors)) {
                            message = err.errors[0];
                          } else {
                            message = err.errors;
                          }
                        } else {
                          message = "Koneksi ke server gagal.";
                        }
                        global.swal("Error", message, "info");
                      }
                    } else {
                      global.swal.stopLoading();
                      global.swal.close();
                    }
                  });
              }
            });
        })

        .catch(err => {
          if (err) {
            if (err.code !== 403) {
              let message;
              if ('errors' in err) {
                if (Array.isArray(err.errors)) {
                  message = err.errors[0];
                } else {
                  message = err.errors;
                }
              } else {
                message = "Koneksi ke server gagal.";
              }
              global.swal("Error", message, "info");
            }
          } else {
            global.swal.stopLoading();
            global.swal.close();
          }
        });
    }
  };

  handleErrors = async (response) => {
    if (!response.ok) {
      const errors = await response.clone().json();
      const errorMessage = { code: response.status, ...errors }
      throw errorMessage;
    }
    return response;
  }

  // fungsi setelah bank di pilih
  async onSendPayment() {
    const { form } = this.state;
    const { pg_code } = form.value;

    if (isEmpty(pg_code)) {
      global.swal(
        "Metode pembayaran  belum di pilih.",
        "Mohon dipilih terlebih dahulu",
        "info"
      );
    } else {
      if (pg_code == "mandiri_h2h") {
        this.openModalDetail(); // buka modal detail
      } else {
        await this.detailPayments(); // simpan pembayaran
        this.openModalDetail(); // buka modal detail
      }
    }
  }

  // fungsi simpan detail pembayaran
  async detailPayments() {
    const { form, organizations_id } = this.state;
    const { value } = form;
    const { bank_name } = form.value;

    Swal.fire({
      title: "Mohon Tunggu Sebentar !",
      html: "Tagihan sedang diproses",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    console.log(bank_name)

    let payload = {};
    if (organizations_id == 3) {
      payload = global.variable.LINK_API_AKADEMIS + "payments/detail-payments";
    }
    else if (organizations_id !== 3 && bank_name !== 'VA BCA RICCI') {
      payload = global.variable.LINK_API_AKADEMIS + "payments-general/detail-payments";
    }
    else if (organizations_id !== 3 && bank_name == 'VA BCA RICCI') {
      payload = global.variable.LINK_API_AKADEMIS + "payments-general/detail-payments-va";
    }

    await trackPromise(
      fetch(payload, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      })
        .then((response) => response.json())
        .then((resData) => {
          if (!isEmpty(resData)) {
            this.setState({ payload: resData });
          } else {
            global.swal("Error", "Koneksi gagal.", "info");
            Swal.close();
          }
        })
        .catch((err) => {
          Swal.close();
          if (err) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          } else {
            global.swal.stopLoading();
            global.swal.close();
          }
        })
    );
  }

  // fungsi show detail setelah simpan detail pembayaran
  async onShowDetail(data) {
    console.log(data)
    this.openModalShowDetail(data); // buka modal detail
  }

  // fungsi batalkan detail pembayaran
  async cancelPayments(data) {
    Swal.fire({
      title: "Mohon Tunggu Sebentar !",
      html: "Tagihan sedang dibatalkan",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    await trackPromise(
      fetch(global.variable.LINK_API_AKADEMIS + "payments/cancel-payments", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((resData) => {
          if (!isEmpty(resData)) {
            if (resData.payment_status_code == "1") {
              global.swal(
                "Tagihan Belum Bisa Dibatalkan",
                resData.expired_date,
                "info"
              );
              Swal.close();
            } else {
              this.setState({ payload: [] });
              this.refreshPayments();
            }
          } else {
            global.swal("Error", "Koneksi gagal.", "info");
            Swal.close();
          }
        })
        .catch((err) => {
          this.setState({ payload: [] });
          Swal.close();
          if (err) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          } else {
            global.swal.stopLoading();
            global.swal.close();
          }
        })
    );
  }

  // fungsi refresh list pembayaran & close modal
  async refreshPayments() {
    this.setState((prevState) => ({
      payload: [],
      form: {
        value: {
          ...prevState.form.value,
          pg_code: "",
          bank_name: "",
          admin_fee: 0,
          subtotal: 0,
          total: 0,
        },
      },
    }));
    this._onSearchContent();
    MySwal.close();
  }

  async openModalRincian() {
    // reset bank jika sdh di pilih
    await this.setState((prevState) => ({
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          pg_code: "",
          bank_name: "",
          admin_fee: 0,
        },
      },
    }));
    MySwal.fire({
      title: <p>Rincian Pembayaran</p>,
      width: "350px",
      html: this.renderModalContent(),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  openModalMethod() {
    MySwal.fire({
      title: <p>Pilih Metode Pembayaran</p>,
      width: "550px",
      html: this.renderModalContentMethod(),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  openModalDetail() {
    MySwal.fire({
      width: "450px",
      html: this.renderModalContentDetail(),
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  openModalShowDetail(data) {
    console.log(data)
    MySwal.fire({
      width: "450px",
      html: this.renderModalContentShowDetail(data),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  renderModalContent() {
    const { form, filters } = this.state;
    const { jenis } = filters;
    const { detail_checked, total } = form.value;

    var last = "";

    return (
      <div>
        <hr style={{ margin: "unset" }}></hr>
        <MDBTable style={{ marginBottom: "5px" }}>
          <thead>
            <tr>
              <th style={{ border: "none", textAlign: "left" }}>Jumlah</th>
              <th style={{ border: "none", textAlign: "right" }}>
                <CurrencyFormat
                  input="number"
                  prefix={"Rp. "}
                  readOnly
                  value={total}
                  maxLength="25"
                  thousandSeparator={true}
                  style={{ width: "100%" }}
                  displayType={"text"}
                />
                {/* Rp. {commaFormatted(total)} */}
              </th>
            </tr>
          </thead>
        </MDBTable>
        <MDBTable striped bordered>
          <thead>
            <tr>
              <th
                colSpan="2"
                style={{ textAlign: "left", paddingBlock: "10px" }}
              >
                Detail Pembayaran
              </th>
            </tr>
          </thead>
          <tbody>
            {map(detail_checked, (list, idx) => {
              return (
                <tr key={`${idx}`}>
                  <td style={{ textAlign: "left", paddingBlock: "10px" }}>
                    {list.invoices_id}
                  </td>
                  <td style={{ textAlign: "right", paddingBlock: "10px" }}>
                    <CurrencyFormat
                      input="number"
                      prefix={"Rp. "}
                      readOnly
                      value={list.nominal}
                      maxLength="25"
                      thousandSeparator={true}
                      style={{ width: "100%" }}
                      displayType={"text"}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </MDBTable>
        <div className="row">
          <div className="col-md-12">
            <MDBBtn
              color="primary"
              block
              onClick={() => this.openModalMethod()}
            >
              Lanjut
            </MDBBtn>
          </div>
        </div>
      </div>
    );
  }

  renderModalContentMethod() {
    const { filters, listbank, organizations_id } = this.state;
    const { jenis } = filters;

    const h2h = [{
      name: 'H2H Mandiri', pg_code: 'mandiri_h2h', admin_fee: 0,
    }];

    const listh2h = h2h.concat(listbank);

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          pg_code: "",
          bank_name: "",
          admin_fee: 0,
        },
      },
    }));

    let ket = "";

    return (
      <div>
        <hr style={{ margin: "unset" }}></hr>
        <br></br>

        <form
          className="form"
          style={{ backgroundColor: "#ccc", padding: "15px" }}
        >
          {map(organizations_id == 3 ? listh2h : listbank, (list, idx) => (
            <div className="inputGroup" key={idx}>
              {(() => {
                if (list.pg_code == "mandiri_h2h") {
                  ket = "";
                } else if (list.admin_fee == 0 && list.admin_percent != 0) {
                  ket = " (Biaya admin: " + list.admin_percent + "%)";
                } else {
                  ket = " (Biaya admin: Rp. " + list.admin_fee + ")";
                }
              })()}
              <input
                id={"pg_code" + idx}
                name="pg_code"
                type="radio"
                value={list.pg_code}
                bank_name={list.name}
                admin_fee={list.admin_fee}
                admin_percent={list.admin_percent}
                onChange={this._onFormChange}
              />

              <label htmlFor={"pg_code" + idx}>{list.name + ket}</label>
            </div>
          ))}
        </form>
        <br></br>

        <div className="row">
          <div className="col-md-6" style={{ paddingBlock: "2px" }}>
            <MDBBtn
              color="primary"
              block
              onClick={() => this.openModalRincian()}
            >
              Kembali
            </MDBBtn>
          </div>
          <div className="col-md-6" style={{ paddingBlock: "2px" }}>
            <MDBBtn color="primary" block onClick={() => this.onSendPayment()}>
              Lanjut
            </MDBBtn>
          </div>
        </div>
      </div>
    );
  }

  renderModalContentDetail() {
    const { form, payload } = this.state;
    const { total, admin_fee, subtotal, bank_name, detail_checked } = form.value;
    let va_h2h = this.state.listinvoice[0].temps_id + this.state.listinvoice[0].periode

    return (
      <div>
        <br></br>
        <form className="form">
          <img style={{ width: "70px" }} alt="logo" src={Logo} />
          <br></br>
          <h4 style={{ margin: "unset", fontWeight: "bold" }}>
            Yayasan Pangudi Luhur
          </h4>
          <br></br>
          <h5 style={{ margin: "unset", color: "#3c8dbc" }}>
            Detail Transaksi Pemesanan
          </h5>
          <br></br>
          <div style={{ textAlign: "left" }}>
            <h6 style={{ margin: "unset" }}>Pembayaran Via: </h6>
            <br></br>
            <h5 style={{ margin: "unset", textAlign: "center" }}>
              {bank_name}
            </h5>
            <br></br>
          </div>
          <MDBTable striped>
            <tbody>
              {isEmpty(payload) ? (
                <>
                  <tr>
                    <td style={{ textAlign: "left" }}>No Virtual Account</td>
                    <td style={{ textAlign: "right" }}>
                      {va_h2h}
                      <CopyToClipboard text={va_h2h}>
                        <span className="copytoclipboard">
                          <i class="fa fa-copy"></i>
                        </span>
                      </CopyToClipboard>
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <td style={{ paddingBlock: "5px", textAlign: "left" }}>
                      No Virtual Account
                    </td>
                    <td style={{ paddingBlock: "5px", textAlign: "right" }}>
                      {payload.trx_id}
                      <CopyToClipboard text={payload.trx_id}>
                        <span className="copytoclipboard">
                          <i class="fa fa-copy"></i>
                        </span>
                      </CopyToClipboard>
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <td style={{ paddingBlock: "5px", textAlign: "left" }}>
                  No Order
                </td>
                <td style={{ paddingBlock: "5px", textAlign: "right" }}>
                  {payload.bill_no}
                </td>
              </tr>
              <tr>
                <td style={{ paddingBlock: "5px", textAlign: "left" }}>
                  Subtotal
                </td>
                <td style={{ paddingBlock: "5px", textAlign: "right" }}>
                  <CurrencyFormat
                    input="number"
                    prefix={"Rp. "}
                    readOnly
                    value={subtotal}
                    maxLength="25"
                    thousandSeparator={true}
                    style={{ width: "100%" }}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ paddingBlock: "5px", textAlign: "left" }}>
                  Biaya Admin
                </td>
                <td style={{ paddingBlock: "5px", textAlign: "right" }}>
                  <CurrencyFormat
                    input="number"
                    prefix={"Rp. "}
                    readOnly
                    value={admin_fee}
                    maxLength="25"
                    thousandSeparator={true}
                    style={{ width: "100%" }}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ paddingBlock: "5px", textAlign: "left" }}>
                  Total Pembayaran
                </th>
                <th style={{ paddingBlock: "5px", textAlign: "right" }}>
                  <CurrencyFormat
                    input="number"
                    prefix={"Rp. "}
                    readOnly
                    value={total}
                    maxLength="25"
                    thousandSeparator={true}
                    style={{ width: "100%" }}
                    displayType={"text"}
                  />
                </th>
              </tr>
            </tbody>
          </MDBTable>
          <div className="row">
            <div className="col-md-12">
              <MDBBtn color="primary" block onClick={() => this.refreshPayments()}>
                Tutup
              </MDBBtn>
            </div>
          </div>
        </form>
        <br></br>
      </div>
    );
  }

  renderModalContentShowDetail(data) {
    console.log(data.trx_id_va)
    return (
      <div>
        <br></br>
        <form className="form">
          <img style={{ width: "70px" }} alt="logo" src={Logo} />
          <br></br>
          <h4 style={{ margin: "unset", fontWeight: "bold" }}>
            Yayasan Pangudi Luhur
          </h4>
          <br></br>
          <h5 style={{ margin: "unset", color: "#3c8dbc" }}>
            Detail Transaksi Pemesanan
          </h5>
          <br></br>
          <div style={{ textAlign: "left" }}>
            <h6 style={{ margin: "unset" }}>Pembayaran Via: </h6>
            <br></br>
            <h5 style={{ margin: "unset", textAlign: "center" }}>
              {data.bank_name}
            </h5>
            <br></br>
          </div>
          <MDBTable striped>
            <tbody>
              <tr>
                <td style={{ paddingBlock: "5px", textAlign: "left" }}>
                  No Virtual Account
                </td>
                <td style={{ paddingBlock: "5px", textAlign: "right" }}>
                  {data.trx_id ? data.trx_id : data.trx_id_va}
                  <CopyToClipboard text={data.trx_id ? data.trx_id : data.trx_id_va}>
                    <span className="copytoclipboard">
                      <i class="fa fa-copy"></i>
                    </span>
                  </CopyToClipboard>
                </td>
              </tr>
              <tr>
                <td style={{ paddingBlock: "5px", textAlign: "left" }}>
                  No Order
                </td>
                <td style={{ paddingBlock: "5px", textAlign: "right" }}>
                  {data.faspay_id ? data.faspay_id : data.va_id}
                </td>
              </tr>
              <tr>
                <td style={{ paddingBlock: "5px", textAlign: "left" }}>
                  Subtotal
                </td>
                <td style={{ paddingBlock: "5px", textAlign: "right" }}>
                  <CurrencyFormat
                    input="number"
                    prefix={"Rp. "}
                    readOnly
                    value={data.total_va ? data.total_va - data.admin_fee_va : (data.total - data.admin_fee)}
                    maxLength="25"
                    thousandSeparator={true}
                    style={{ width: "100%" }}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ paddingBlock: "5px", textAlign: "left" }}>
                  Biaya Admin
                </td>
                <td style={{ paddingBlock: "5px", textAlign: "right" }}>
                  <CurrencyFormat
                    input="number"
                    prefix={"Rp. "}
                    readOnly
                    value={(data.admin_fee ? data.admin_fee : data.admin_fee_va)}
                    maxLength="25"
                    thousandSeparator={true}
                    style={{ width: "100%" }}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ paddingBlock: "5px", textAlign: "left" }}>
                  Total Pembayaran
                </th>
                <th style={{ paddingBlock: "5px", textAlign: "right" }}>
                  <CurrencyFormat
                    input="number"
                    prefix={"Rp. "}
                    readOnly
                    value={(data.total ? data.total : data.total_va)}
                    maxLength="25"
                    thousandSeparator={true}
                    style={{ width: "100%" }}
                    displayType={"text"}
                  />
                </th>
              </tr>
            </tbody>
          </MDBTable>
        </form>
        <br></br>
        <div className="row">
          <div className="col-md-12" style={{ paddingBlock: "5px" }}>
            <MDBBtn
              color="primary"
              style={{ padding: "7px" }}
              block
              onClick={() => this.cancelPayments(data)}
            >
              Batalkan Pembayaran
            </MDBBtn>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ paddingBlock: "5px" }}>
            <MDBBtn
              color="primary"
              style={{ padding: "7px" }}
              block
              onClick={() => MySwal.close()}
            >
              Tutup
            </MDBBtn>
          </div>
        </div>
        <br></br>
      </div>
    );
  }

  handleBack = () => {
    this.props.history.push("/bayarpendaftaran");
  };

  // handleSubmit = async event => {
  //   event.preventDefault();
  //   await this.uploadfile();
  // };

  render() {
    const { filters, form, listinvoice } = this.state;
    const { detail_checked } = form.value;
    form.value.subtotal = 0;
    map(
      detail_checked,
      (data, idx) =>
        (form.value.subtotal += Number(form.value.detail_checked[idx].nominal))
    );
    form.value.total = form.value.subtotal + form.value.admin_fee;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <form onSubmit={filters.jenis == "2" ? this.onBayar : this.fileUploadHandler}>
                    <MDBCard className="mb-5">
                      <MDBCardBody>
                        <MDBBreadcrumb color="cyan lighten-4">
                          <MDBBreadcrumbItem active>
                            <a href="/home">Home</a>
                          </MDBBreadcrumbItem>
                          <MDBBreadcrumbItem active>
                            Bayar Formulir
                          </MDBBreadcrumbItem>
                        </MDBBreadcrumb>

                        <div className="row">
                          <div className="col-md-6">
                            <label
                              htmlFor="exampleForm2"
                              style={{ fontWeight: "bold" }}
                            >
                              Nama Siswa
                            </label>
                            <input
                              className="form-control"
                              type="Text"
                              name="jalur"
                              value={localStorage.getItem("DATA_JALUR")}
                              disabled
                            />
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="exampleForm2"
                              style={{ fontWeight: "bold" }}
                            >
                              Jenis Pembayaran
                            </label>
                            <select
                              className="form-control"
                              name="jenis"
                              value={filters.jenis}
                              onChange={this.onDropdownSelected.bind(this)}
                              disabled
                            >
                              <option value="">- Pilih Jenis Pembayaran -</option>
                              {this.createSelectItems()}
                            </select>
                          </div>
                        </div>
                        <hr />
                        {filters.jenis == "2" && (
                          <div className="box-body">
                            <label style={{ fontSize: 20 }}>
                              Payment Gateway
                            </label>
                            <MDBTable bordered>
                              <thead className="blue-grey lighten-4">
                                <tr>
                                  {/* <th></th> */}
                                  <th>No Invoice</th>
                                  {/* <th>No VA H2H</th> */}
                                  <th>Nominal</th>
                                  {/* <th></th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {listinvoice.map((data, idx) => {
                                  return (
                                    <tr key={idx}>
                                      {/* <td>
                                        <input
                                          type="checkbox"
                                          name="status"
                                          data-input-array={true}
                                          data-array-position={idx}
                                          data-field-name="listinvoice"
                                          onChange={this._onFormChange}
                                          checked={detail_checked[idx] ? true : ""}
                                          value={data.status}
                                        />
                                      </td> */}
                                      <td style={{ verticalAlign: "middle" }}>
                                        {data.invoices_id}
                                      </td>
                                      {/* <td style={{ verticalAlign: "middle" }}>
                                        {data.no_va_h2h}
                                      </td> */}
                                      <td style={{ verticalAlign: "middle" }}>
                                        <CurrencyFormat
                                          input="number"
                                          prefix={"Rp. "}
                                          readOnly
                                          value={data.nominal}
                                          maxLength="25"
                                          thousandSeparator={true}
                                          style={{ width: "100%" }}
                                          displayType={"text"}
                                        />
                                      </td>
                                      {/* <td style={{ verticalAlign: "middle" }}>
                                        {(data.faspay_id !== null ) ? (
                                          <MDBBtn color="primary" block onClick={() => this.onShowDetail(data)}>
                                            Lihat Tagihan
                                          </MDBBtn>
                                        ) : (
                                          <MDBBtn color="primary" block type="submit">
                                            Bayar
                                          </MDBBtn>
                                        )}
                                      </td> */}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </MDBTable>
                            {this.state.is_show && (
                              <div className="row">
                                {/* <div className="col-md-7">
                                  <p style={{ fontWeight: "bold" }}>
                                    Total: &emsp; Rp. {form.value.subtotal}
                                  </p>
                                </div> */}
                                <div
                                  className="col-md-6"
                                  style={{ paddingBlock: "2px" }}
                                >
                                  <MDBBtn
                                    color="primary"
                                    onClick={this.handleBack}
                                    block
                                  >
                                    Kembali
                                  </MDBBtn>
                                </div>
                                <div
                                  className="col-md-6"
                                  style={{ paddingBlock: "2px" }}
                                >
                                  {!isEmpty(listinvoice) &&
                                    (listinvoice[0].faspay_id !== null || (listinvoice[0].va_id && listinvoice[0].va_id !== null) ? (
                                      <MDBBtn
                                        color="primary"
                                        block
                                        onClick={() =>
                                          this.onShowDetail(listinvoice[0])
                                        }
                                      >
                                        Lihat Tagihan
                                      </MDBBtn>
                                    ) : (
                                      <MDBBtn color="primary" block type="submit">
                                        Bayar
                                      </MDBBtn>
                                    ))}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        {filters.jenis == "1" && (
                          <div className="box-body">
                            <label style={{ fontSize: 20 }}>
                              Upload Dokumen
                            </label>
                            <div className="row">
                              <div className="col-md-6 form-no-m">
                                <label
                                  htmlFor="exampleForm2"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Nama Bank
                                </label>
                                <input
                                  className="form-control"
                                  type="Text"
                                  name="nama_bank"
                                  maxLength="50"
                                  value={this.state.nama_bank || ''}
                                  onChange={this.onChange}
                                  style={{ margin: "unset" }}
                                  disabled
                                />
                                <br></br>
                              </div>
                              <div className="col-md-6 form-no-m">
                                <label
                                  htmlFor="exampleForm2"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Nama Rekening
                                </label>
                                <input
                                  className="form-control"
                                  type="Text"
                                  name="nama_rek"
                                  maxLength="50"
                                  value={this.state.nama_rek || ''}
                                  style={{ margin: "unset" }}
                                  disabled
                                />
                                <br></br>
                              </div>
                              <div className="col-md-6 form-no-m">
                                <label
                                  htmlFor="exampleForm2"
                                  style={{ fontWeight: "bold" }}
                                >
                                  No. Rekening
                                </label>
                                <input
                                  className="form-control"
                                  type="Text"
                                  name="no_rekening"
                                  maxLength="50"
                                  value={this.state.no_rekening || ''}
                                  style={{ margin: "unset" }}
                                  disabled
                                />
                                <br></br>
                              </div>
                              <div className="col-md-6 form-no-m">
                                <label
                                  htmlFor="exampleForm2"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Uang Pendaftaran
                                </label>
                                <CurrencyFormat
                                  className="form-control"
                                  input="number"
                                  name="uang_pendaftaran"
                                  value={this.state.uang_pendaftaran || 0}
                                  thousandSeparator={true}
                                  style={{ margin: "unset" }}
                                  disabled
                                />
                                <br></br>
                              </div>
                              <div className="col-md-6 form-no-m">
                                <label
                                  htmlFor="exampleForm2"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Catatan
                                </label>
                                <input
                                  className="form-control"
                                  type="Text"
                                  name="note"
                                  maxLength="50"
                                  value={this.state.note || ''}
                                  onChange={this.onChange}
                                  style={{ margin: "unset" }}
                                />
                                <br></br>
                              </div>
                              <div className="col-md-6 form-no-m">
                                <label
                                  htmlFor="exampleForm2"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Upload file gambar (*jpg dan *.png) atau pdf
                                </label>
                                <input
                                  type="file"
                                  name="file"
                                  className="form-control"
                                  onChange={this.onChangeHandler}
                                />
                                <br></br>
                              </div>
                            </div>
                            <div className="row">
                              {this.state.note_return ? (
                                <>
                                  <div className="col-md-6">
                                    <label
                                      htmlFor="exampleForm2"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Catatan Verifikasi
                                    </label>
                                    <input
                                      className="form-control"
                                      type="Text"
                                      name="note__return"
                                      maxLength="50"
                                      value={this.state.note_return}
                                      onChange={this.onChange}
                                      style={{ margin: "unset" }}
                                      disabled
                                    />
                                  </div>
                                </>
                              ) : ''}
                              <div className="col-md-6 d-flex flex-column">
                                {this.state.image_link ? (
                                  <>
                                    <label
                                      htmlFor="exampleForm2"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      File gambar terakhir
                                    </label>
                                    <div>
                                      <Link
                                        to=""
                                        target="_blank"
                                        onClick={this.download(this.state.image_link)}
                                      >
                                        Lihat File
                                      </Link>
                                    </div>
                                  </>
                                ) : ''}
                              </div>
                            </div>
                            <br></br>
                            <div className="row">
                              {/* <div className="col-md-7">
                                <p style={{ fontWeight: "bold" }}>
                                  Total: &emsp; Rp. {form.value.subtotal}
                                </p>
                              </div> */}
                              <div
                                className="col-md-6"
                                style={{ paddingBlock: "2px" }}
                              >
                                <MDBBtn
                                  color="primary"
                                  onClick={this.handleBack}
                                  block
                                >
                                  Kembali
                                </MDBBtn>
                              </div>
                              <div
                                className="col-md-6"
                                style={{ paddingBlock: "2px" }}
                              >
                                <MDBBtn color="primary" block type="submit">
                                  Submit
                                </MDBBtn>
                              </div>
                            </div>
                          </div>
                        )}
                      </MDBCardBody>
                    </MDBCard>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Bayar_pendaftaran_detail;
