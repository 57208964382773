import React, { Component } from "react";
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBCard,
    MDBCardBody,
    MDBTable,
    MDBBtn,
} from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

class List_user extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
        };
    }

    componentDidMount() {
        fetch(global.variable.LINK_API + "settings/master/listUser", {
            method: "post",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + global.cookies.get("access_token"),
            },
        })
            .then((response) => response.json())
            .then((resData) => {
                if (resData.message === "Success") {
                    this.setState({
                        dataList: resData.data,
                    });
                } else {
                    global.swal("Error", resData.errors[0], "info");
                }
            });
    }

    onClickTambahUser = e => {
        e.preventDefault();
        localStorage.setItem("DATA_USER", null);
        this.props.history.push("/tambahuser")
    }

    onClickEditUser = (user) => e => {
        e.preventDefault();
        localStorage.setItem("DATA_USER", JSON.stringify(user));
        this.props.history.push("/tambahuser")
    }

    onClickDeleteUser = (user) => e => {
        e.preventDefault();
        global.swal({
            text: "Hapus user ini?",
            closeOnEsc: false,
            closeOnClickOutside: false,
            buttons: ["Batal", { text: "Hapus", closeModal: false }],
        }).then((confirm) => {
            if (!confirm) {
                return
            }
            fetch(global.variable.LINK_API + "user/delete/" + user.id, {
                method: "delete",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + global.cookies.get("access_token"),
                },
            })
                .then((response) => response.json())
                .then((res) => {
                    if (res.code === 200) {
                        window.location.reload();
                    } else {
                        global.swal("Error", res.message, "info");
                    }
                });
        })
    }

    render() {
        let counter = 0;
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box">
                                <div className="divContainter">
                                    <MDBCard className="mb-5">
                                        <MDBCardBody>
                                            <MDBBreadcrumb color="cyan lighten-4">
                                                <MDBBreadcrumbItem active>
                                                    <a href="/home">Home</a>
                                                </MDBBreadcrumbItem>
                                                <MDBBreadcrumbItem active>
                                                    Daftar User
                                                </MDBBreadcrumbItem>
                                            </MDBBreadcrumb>
                                            <MDBBtn color="primary" block type="submit" onClick={this.onClickTambahUser}>Tambah User</MDBBtn>
                                            <br></br>
                                            <MDBTable>
                                                <thead className="blue-grey lighten-4">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Username</th>
                                                        <th>Nama</th>
                                                        <th>User Level</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.dataList.map((item, idx) => {
                                                        counter++;
                                                        return (
                                                            <tr key={idx}>
                                                                <td>{counter}</td>
                                                                <td>{item.user_id}</td>
                                                                <td>{item.name}</td>
                                                                <td>{item.user_level.name}</td>
                                                                <td style={{ maxWidth: "100px" }}>
                                                                    <button className="btn btn-warning" style={style.button} onClick={this.onClickEditUser(item)}>
                                                                        <FontAwesomeIcon icon={faEdit} ></FontAwesomeIcon>
                                                                    </button>
                                                                    <button className="btn btn-danger" style={style.button} onClick={this.onClickDeleteUser(item)}>
                                                                        <FontAwesomeIcon icon={faTrash} ></FontAwesomeIcon>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </MDBTable>
                                        </MDBCardBody>
                                    </MDBCard>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const style = {
    button: {
        backgroundColor: '#28a745',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '5px',
        cursor: 'pointer',
        // display: 'flex',
        // alignItems: 'center',
    },
}

export default List_user;