import React, { Component, useEffect, useState, useCallback } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import "moment/locale/id.js";
import "rc-datepicker/lib/style.css";
import CurrencyFormat from "react-currency-format";
import { trackPromise } from "react-promise-tracker";
import { isEmpty, map } from "lodash";
import remSep from "string-remove-thousand-separators";

import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
} from "mdbreact";
import LoadingSpinner from "../components/components/LoadingSpinner";
import "../components/css/App.css";
// import remSep from "string-remove-thousand-separators";

const Pendaftaran = ({ history }) => {

  const [state, setState] = useState({
    isLoading: true,
    studentData: {},
    student_id: "",
    registration_type: "",
    fullname: "",
    nickname: "",
    gender_id: "",
    nik: "",
    no_kk: "",
    nis: "",
    nisn: "",
    pob: "",
    dob: "",
    birth_certificate: "",
    class: "",
    sibling: [],
    achieve: [],
    data: [],
    data_list: [],
    fields: {},
    students: {},
    parent_status_id: "",
  });

  const [showAlert, setShowAlert] = useState(false);

  const changeState = (data = {}) => setState(prev => ({ ...prev, ...data }))

  const setDatatoField = useCallback(() => {
    let student_id = '';
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER")).pdf_guardian;
    var set = JSON.parse(localStorage.getItem("STUDENT_DETAIL")).hp;
    set = set.replace("+62", "0");
    let guardian_id1 = params[0].id;
    let guardian_id2 = params[1].id;
    let guardian_id3 = params[2].id;
    let guardian_id_name1 = params[0].name;
    let guardian_id_name2 = params[1].name;
    let guardian_id_name3 = params[2].name;
    let hp = set;
    let fullname1 = '';
    let nik1 = '';
    let dob1 = '';
    let status_guardian_id1 = '';
    let occupation_id1 = '';
    let salary1 = '';
    let hp1 = '';
    let address1 = '';
    let religion_guardian_id1 = '';
    let gender_id1 = '';
    let education_id1 = '';
    let fullname2 = '';
    let nik2 = '';
    let dob2 = '';
    let status_guardian_id2 = '';
    let occupation_id2 = '';
    let salary2 = '';
    let hp2 = '';
    let address2 = '';
    let religion_guardian_id2 = '';
    let gender_id2 = '';
    let education_id2 = '';
    let fullname3 = '';
    let nik3 = '';
    let dob3 = '';
    let status_guardian_id3 = '';
    let occupation_id3 = '';
    let salary3 = '';
    let hp3 = '';
    let address3 = '';
    let religion_guardian_id3 = '';
    let gender_id3 = '';
    let status_guardian = '';
    let education_id3 = '';
    let listSibling = [];
    let listAchieve = [];

    let data_list = JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_ms_student;
    let studentData = JSON.parse(localStorage.getItem("STUDENT_DETAIL"));
    student_id = studentData.registration_id;
    if (studentData.note_return) {
      showAlertNotification();
    }
    if (data_list.length > 0) {
      data_list.forEach((item, idx) => {
        if (studentData[item.field] !== null && studentData[item.field] !== undefined) {
          switch (item.type_text) {
            case "text":
              item.value = studentData[item.field].toString();
              break;
            case "number":
              item.value = parseInt(studentData[item.field]);
              break;
            default:
              item.value = studentData[item.field];
              break;
          }
        }

        var set = JSON.parse(localStorage.getItem("STUDENT_DETAIL")).hp;
        set = set.replace("+62", "0");
        if (item.field === "hp") {
          item.value = set;
        }
      });
      let parent_status_id = studentData.parent_status_id;
      let both_parent_work = studentData.working_parent_id;
      studentData.guardian.forEach(data => {
        if (data.guardian_id === 1) {
          guardian_id1 = data.guardian_id;
          fullname1 = data.fullname;
          nik1 = data.nik;
          dob1 = data.dob;
          status_guardian_id1 = data.status_guardian_id;
          occupation_id1 = data.occupation_id;
          salary1 = data.salary;
          hp1 = data.hp;
          address1 = data.address;
          religion_guardian_id1 = data.religion_guardian_id;
          gender_id1 = data.gender_id;
          education_id1 = data.education_id;
        }
        if (data.guardian_id === 2) {
          guardian_id2 = data.guardian_id;
          fullname2 = data.fullname;
          nik2 = data.nik;
          dob2 = data.dob;
          status_guardian_id2 = data.status_guardian_id;
          occupation_id2 = data.occupation_id;
          salary2 = data.salary;
          hp2 = data.hp;
          address2 = data.address;
          religion_guardian_id2 = data.religion_guardian_id;
          gender_id2 = data.gender_id;
          education_id2 = data.education_id;
        }
        if (data.guardian_id === 3) {
          guardian_id3 = data.guardian_id;
          fullname3 = data.fullname;
          nik3 = data.nik;
          dob3 = data.dob;
          status_guardian_id3 = data.status_guardian_id;
          occupation_id3 = data.occupation_id;
          salary3 = data.salary;
          hp3 = data.hp;
          address3 = data.address;
          religion_guardian_id3 = data.religion_guardian_id;
          gender_id3 = data.gender_id;
          status_guardian = data.status_guardian;
          education_id3 = data.education_id;
        }
      });
      if (studentData.sibling.length > 0) {
        studentData.sibling.forEach(data => {
          listSibling.push({
            name: data.name,
            class: data.class,
          });
        })
      }
      if (studentData.achieve.length > 0) {
        studentData.achieve.forEach(data => {
          listAchieve.push({
            achieve_type_id: data.achieve_type_id,
            name: data.name,
            achieve_rank_id: data.achieve_rank_id,
            promoter: data.promoter,
            rank: data.rank,
          });
        })
      }
      changeState({
        studentData,
        student_id,
        hp,
        guardian_id1,
        guardian_id2,
        guardian_id3,
        guardian_id_name1,
        guardian_id_name2,
        guardian_id_name3,
        data_list,
        parent_status_id,
        fullname1,
        nik1,
        dob1,
        status_guardian_id1,
        occupation_id1,
        salary1,
        hp1,
        address1,
        religion_guardian_id1,
        gender_id1,
        education_id1,
        fullname2,
        nik2,
        dob2,
        status_guardian_id2,
        occupation_id2,
        salary2,
        hp2,
        address2,
        religion_guardian_id2,
        gender_id2,
        education_id2,
        fullname3,
        nik3,
        dob3,
        status_guardian_id3,
        occupation_id3,
        salary3,
        hp3,
        address3,
        religion_guardian_id3,
        gender_id3,
        status_guardian,
        education_id3,
        both_parent_work,
        isLoading: false,
        sibling: listSibling,
        achieve: listAchieve,
      })
    }
  }, [])

  const showAlertNotification = () => {
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 10000);
  }

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {

      fetch(global.variable.LINK_API + "auth/parameter", {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
      })
        .then((response) => response.json())
        .then((resData) => {
          if (resData.message === "Success") {
            localStorage.setItem("DATA_PARAMETER", JSON.stringify(resData.data));
          }
        });

      fetch(global.variable.LINK_API + "student/detail", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        body: JSON.stringify({
          'id': localStorage.getItem("DATA_JALUR").split("-")[0]
        })
      })
        .then((response) => response.json())
        .then((resData) => {
          if (resData.code === 200) {
            localStorage.setItem("STUDENT_DETAIL", JSON.stringify(resData.data));
            setDatatoField();
          } else {
            global.swal('Info', resData.message, 'info');
          }
        });
    }
    return () => {
      subscribe = false
    }
  }, [setDatatoField]);


  const createSelectGender = () => {
    let items = [];
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER")).pdf_gender;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  const createSelectParentStatus = () => {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_parent_status;

    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  const createSelectStatusGuardian = () => {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_status_guardian;

    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  const createSelectWorkingParents = () => {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_working_parents;

    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  const onChangeData2 = (e, index) => {
    let data = state.sibling;
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    data[index][e.target.name] = a;
    changeState({
      data
    });
  };

  const onChangeDataAchieve = (e, index) => {
    let data = state.achieve;
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    data[index][e.target.name] = a;
    changeState({
      data
    });
  };

  const onDropdownSelectedSibling = (e, index) => {
    let data = state.sibling;
    data[index][e.target.name] = e.target.value;
    changeState({
      data
    });
  }

  const onDropdownSelectedAchieve = (e, index) => {
    let data = state.achieve;
    data[index][e.target.name] = e.target.value;
    changeState({
      data
    });
  }

  const createSelectAchieveType = () => {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_achieve_type;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  const createSelectAchieveRank = () => {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_achieve_rank;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  const showRemove = () => {
    if (state.sibling != "") {
      return (
        <MDBBtn
          onClick={handleClear}
          block
          style={{ height: 50 }}
        >
          Clear Data
        </MDBBtn>
      );
    }
  }

  const showPrestasi = () => {
    if (state.sibling) {
      let guardian = state.sibling;
      let showitem = [];

      for (let i = 0; i < guardian.length; i++) {
        showitem.push(
          <div key={guardian[i].name}>
            <hr />
            <div className="rowHorizontal">
              <label className="col-md-3" style={{ width: 200, margin: 5, fontWeight: "bold" }} >
                Nama Siswa{" "}
              </label>
              <label className="col-md-6" style={{ margin: 5, fontWeight: "bold" }} >
                {guardian[i].name}{" "}
              </label>
            </div>
            <div className="rowHorizontal">
              <label className="col-md-3" style={{ width: 200, margin: 5, fontWeight: "bold" }} >
                Kelas{" "}
              </label>
              <label className="col-md-6" style={{ margin: 5, fontWeight: "bold" }} >
                {guardian[i].class !== "0" ? guardian[i].class : ""}{" "}
              </label>
            </div>
          </div>
        );
      }

      return showitem;
    }
  }

  const getMaster = (id = 0, table) => {
    var array = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    )[table];
    let name;
    if (id != '') {
      var arrayfilter = array.filter((data) => { if (data.id == id) return data });
      name = arrayfilter.length > 0 ? arrayfilter[0].name : '';
    } else {
      name = '';
    }
    return name;
  }

  const showRemove2 = () => {
    if (state.achieve != "") {
      return (
        <MDBBtn onClick={handleClearAchieve} block style={{ height: 50 }} >
          Clear Data
        </MDBBtn>
      );
    }
  }

  const showPrestasi2 = () => {
    if (state.achieve) {
      let guardian = state.achieve;
      let showitem = [];

      for (let i = 0; i < guardian.length; i++) {
        showitem.push(
          <div key={i}>
            <hr />
            <div className="rowHorizontal">
              <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
                Jenis{" "}
              </label>
              <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
                {getMaster(guardian[i].achieve_type_id, 'pdf_achieve_type')}{" "}
              </label>
            </div>
            <div className="rowHorizontal">
              <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
                Nama Prestasi{" "}
              </label>
              <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
                {guardian[i].name}{" "}
              </label>
            </div>
            <div className="rowHorizontal">
              <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
                Penyelenggara{" "}
              </label>
              <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
                {guardian[i].promoter}{" "}
              </label>
            </div>
            <div className="rowHorizontal">
              <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
                Tingkat{" "}
              </label>
              <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
                {getMaster(guardian[i].achieve_rank_id, 'pdf_achieve_rank')}{" "}
              </label>
            </div>
            <div className="rowHorizontal">
              <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
                Peringkat{" "}
              </label>
              <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
                {guardian[i].rank}{" "}
              </label>
            </div>
          </div>
        );
      }
      return showitem;
    }
  }

  const formGuardian = () => {
    return (
      <div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="exampleForm2">Keadaan Orang Tua <span className="text-danger">*</span></label>
            <select
              className="form-control"
              name="parent_status_id"
              onChange={onDropdownSelected2}
              value={state.parent_status_id}
              required
            >
              <option value="">-Pilih Status-</option>
              {createSelectParentStatus()}
            </select>
          </div>
          {state.parent_status_id !== 4 && (
            <>
              <div className="form-group col-md-6">
                <label htmlFor="exampleForm2">
                  Keadaan Pekerjaan Orang Tua <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  name="both_parent_work"
                  onChange={onDropdownSelected2}
                  value={state.both_parent_work}
                  required
                >
                  <option value="">-Pilih-</option>
                  {createSelectWorkingParents()}
                </select>
              </div>
            </>
          )}
        </div>
        {state.parent_status_id === 1 && (
          <div>
            <label
              style={{
                fontSize: 20,
                textDecorationLine: "underline",
              }}
            >
              Data {state.guardian_id_name1}
            </label>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Nama Lengkap <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  name="fullname1"
                  onChange={onChange}
                  maxLength="200"
                  value={state.fullname1 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>
                  NIK (Nomor Induk Kependudukan) ={">"} cek di Kartu Keluarga <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="nik1"
                  onChange={onChange}
                  value={state.nik1 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Tanggal Lahir <span className="text-danger">*</span></label>
                <input
                  type="date"
                  className="form-control"
                  name="dob1"
                  onChange={onChange}
                  maxLength="200"
                  value={state.dob1 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Agama <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="religion_guardian_id1"
                  onChange={onDropdownSelected2}
                  value={state.religion_guardian_id1}
                  required
                >
                  <option value="">-Pilih Agama-</option>
                  {createSelectReligion()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Status Hubungan<span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="status_guardian_id1"
                  onChange={onDropdownSelected2}
                  value={state.status_guardian_id1}
                  required
                >
                  <option value="">-Pilih Status-</option>
                  {createSelectStatusGuardian()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>Pendidikan Terakhir <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="education_id1"
                  onChange={onDropdownSelected2}
                  value={state.education_id1}
                  required
                >
                  <option value="">-Pilih Pendidikan-</option>
                  {createSelectPendidikan()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Pekerjaan <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="occupation_id1"
                  onChange={onDropdownSelected2}
                  value={state.occupation_id1}
                  style={{ marginTop: "4%" }}
                  required
                >
                  <option value="">-Pilih Pekerjaan-</option>
                  {createSelectPekerjaan()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>
                  Penghasilan (isi hanya angka jangan diberi titik dan koma,
                  jika tidak ada diisi dengan angka 0)
                </label>
                <CurrencyFormat
                  name="salary1"
                  className="form-control"
                  onChange={onChangeNomSalary}
                  value={state.salary1 || ''}
                  maxLength="50"
                  thousandSeparator={true}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>No HP <span className="text-danger">*</span></label>
                <CurrencyFormat
                  className="form-control"
                  input="number"
                  name="hp1"
                  format="+62############"
                  onChange={onChange}
                  value={state.hp1}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Alamat Tempat Tinggal <span className="text-danger">*</span></label>
                <input
                  className="form-control"
                  type="text"
                  name="address1"
                  maxLength="200"
                  value={state.address1 || ''}
                  onChange={onChange}
                  required
                />
              </div>
            </div>
            <label
              style={{
                fontSize: 20,
                textDecorationLine: "underline",
              }}
            >
              Data {state.guardian_id_name2}
            </label>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Nama Lengkap <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  name="fullname2"
                  onChange={onChange}
                  maxLength="200"
                  value={state.fullname2 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>
                  NIK (Nomor Induk Kependudukan) ={">"} cek di Kartu Keluarga <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="nik2"
                  onChange={onChange}
                  value={state.nik2 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Tanggal Lahir <span className="text-danger">*</span></label>
                <input
                  type="date"
                  className="form-control"
                  name="dob2"
                  onChange={onChange}
                  maxLength="200"
                  value={state.dob2 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Agama <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="religion_guardian_id2"
                  onChange={onDropdownSelected2}
                  value={state.religion_guardian_id2}
                  required
                >
                  <option value="">-Pilih Agama-</option>
                  {createSelectReligion()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Status Hubungan <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="status_guardian_id2"
                  onChange={onDropdownSelected2}
                  value={state.status_guardian_id2}
                  required
                >
                  <option value="">-Pilih Status-</option>
                  {createSelectStatusGuardian()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>Pendidikan Terakhir <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="education_id2"
                  onChange={onDropdownSelected2}
                  value={state.education_id2}
                  required
                >
                  <option value="">-Pilih Pendidikan-</option>
                  {createSelectPendidikan()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Pekerjaan <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="occupation_id2"
                  onChange={onDropdownSelected2}
                  value={state.occupation_id2}
                  style={{ marginTop: "4%" }}
                  required
                >
                  <option value="">-Pilih Pekerjaan-</option>
                  {createSelectPekerjaan()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>
                  Penghasilan (isi hanya angka jangan diberi titik dan koma,
                  jika tidak ada diisi dengan angka 0)
                </label>
                <CurrencyFormat
                  name="salary2"
                  className="form-control"
                  onChange={onChangeNomSalary}
                  value={state.salary2 || ''}
                  maxLength="50"
                  thousandSeparator={true}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>No HP <span className="text-danger">*</span></label>
                <CurrencyFormat
                  className="form-control"
                  input="number"
                  name="hp2"
                  format="+62############"
                  onChange={onChange}
                  value={state.hp2 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Alamat Tempat Tinggal <span className="text-danger">*</span></label>
                <input
                  className="form-control"
                  type="text"
                  name="address2"
                  maxLength="200"
                  value={state.address2 || ''}
                  onChange={onChange}
                  required
                />
              </div>
            </div>
          </div>
        )}
        {state.parent_status_id === 2 && (
          <div>
            <label
              style={{
                fontSize: 20,
                textDecorationLine: "underline",
              }}
            >
              Data {state.guardian_id_name1}
            </label>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Nama Lengkap <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  name="fullname1"
                  onChange={onChange}
                  maxLength="200"
                  value={state.fullname1 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>
                  NIK (Nomor Induk Kependudukan) ={">"} cek di Kartu Keluarga <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="nik1"
                  onChange={onChange}
                  value={state.nik1 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Tanggal Lahir <span className="text-danger">*</span></label>
                <input
                  type="date"
                  className="form-control"
                  name="dob1"
                  onChange={onChange}
                  maxLength="200"
                  value={state.dob1 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Agama <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="religion_guardian_id1"
                  onChange={onDropdownSelected2}
                  value={state.religion_guardian_id1}
                  required
                >
                  <option value="">-Pilih Agama-</option>
                  {createSelectReligion()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Status Hubungan <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="status_guardian_id1"
                  onChange={onDropdownSelected2}
                  value={state.status_guardian_id1}
                  required
                >
                  <option value="">-Pilih Status-</option>
                  {createSelectStatusGuardian()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>Pendidikan Terakhir <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="education_id1"
                  onChange={onDropdownSelected2}
                  value={state.education_id1}
                  required
                >
                  <option value="">-Pilih Pendidikan-</option>
                  {createSelectPendidikan()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Pekerjaan <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="occupation_id1"
                  onChange={onDropdownSelected2}
                  value={state.occupation_id1}
                  style={{ marginTop: "4%" }}
                  required
                >
                  <option value="">-Pilih Pekerjaan-</option>
                  {createSelectPekerjaan()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>
                  Penghasilan (isi hanya angka jangan diberi titik dan koma,
                  jika tidak ada diisi dengan angka 0)
                </label>
                <CurrencyFormat
                  name="salary1"
                  className="form-control"
                  onChange={onChangeNomSalary}
                  value={state.salary1}
                  maxLength="50"
                  thousandSeparator={true}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>No HP <span className="text-danger">*</span></label>
                <CurrencyFormat
                  className="form-control"
                  input="number"
                  name="hp1"
                  format="+62############"
                  onChange={onChange}
                  value={state.hp1 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Alamat Tempat Tinggal <span className="text-danger">*</span></label>
                <input
                  className="form-control"
                  type="text"
                  name="address1"
                  maxLength="200"
                  value={state.address1 || ''}
                  onChange={onChange}
                  required
                />
              </div>
            </div>
          </div>
        )}
        {state.parent_status_id === 3 && (
          <div>
            <label
              style={{
                fontSize: 20,
                textDecorationLine: "underline",
              }}
            >
              Data {state.guardian_id_name2}
            </label>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Nama Lengkap <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  name="fullname2"
                  onChange={onChange}
                  maxLength="200"
                  value={state.fullname2 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>
                  NIK (Nomor Induk Kependudukan) ={">"} cek di Kartu Keluarga <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="nik2"
                  onChange={onChange}
                  value={state.nik2 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Tanggal Lahir <span className="text-danger">*</span></label>
                <input
                  type="date"
                  className="form-control"
                  name="dob2"
                  onChange={onChange}
                  maxLength="200"
                  value={state.dob2 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Agama <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="religion_guardian_id2"
                  onChange={onDropdownSelected2}
                  value={state.religion_guardian_id2}
                  required
                >
                  <option value="">-Pilih Agama-</option>
                  {createSelectReligion()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Status Hubungan <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="status_guardian_id2"
                  onChange={onDropdownSelected2}
                  value={state.status_guardian_id2}
                  required
                >
                  <option value="">-Pilih Status-</option>
                  {createSelectStatusGuardian()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>Pendidikan Terakhir <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="education_id2"
                  onChange={onDropdownSelected2}
                  value={state.education_id2}
                  required
                >
                  <option value="">-Pilih Pendidikan-</option>
                  {createSelectPendidikan()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Pekerjaan <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="occupation_id2"
                  onChange={onDropdownSelected2}
                  value={state.occupation_id2}
                  style={{ marginTop: "4%" }}
                  required
                >
                  <option value="">-Pilih Pekerjaan-</option>
                  {createSelectPekerjaan()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>
                  Penghasilan (isi hanya angka jangan diberi titik dan koma,
                  jika tidak ada diisi dengan angka 0)
                </label>
                <CurrencyFormat
                  name="salary2"
                  className="form-control"
                  onChange={onChangeNomSalary}
                  value={state.salary2 || ''}
                  maxLength="50"
                  thousandSeparator={true}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>No HP <span className="text-danger">*</span></label>
                <CurrencyFormat
                  className="form-control"
                  input="number"
                  name="hp2"
                  format="+62############"
                  onChange={onChange}
                  value={state.hp2 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Alamat Tempat Tinggal <span className="text-danger">*</span></label>
                <input
                  className="form-control"
                  type="text"
                  name="address2"
                  maxLength="200"
                  value={state.address2 || ''}
                  onChange={onChange}
                  required
                />
              </div>
            </div>
          </div>
        )}
        {state.parent_status_id === 4 && (
          <div>
            <label
              style={{
                fontSize: 20,
                textDecorationLine: "underline",
              }}
            >
              Data {state.guardian_id_name3}
            </label>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Nama Lengkap <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  name="fullname3"
                  onChange={onChange}
                  maxLength="200"
                  value={state.fullname3 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Jenis Kelamin <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="gender_id3"
                  onChange={onChange}
                  value={state.gender_id3 || ''}
                  required
                >
                  <option value="">-Pilih Jenis Kelamin-</option>
                  {createSelectGender()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>
                  NIK (Nomor Induk Kependudukan) ={">"} cek di Kartu Keluarga <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="nik3"
                  onChange={onChange}
                  value={state.nik3 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Tanggal Lahir <span className="text-danger">*</span></label>
                <input
                  type="date"
                  className="form-control"
                  name="dob3"
                  onChange={onChange}
                  maxLength="200"
                  value={state.dob3 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Agama <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="religion_guardian_id3"
                  onChange={onDropdownSelected2}
                  value={state.religion_guardian_id3}
                  required
                >
                  <option value="">-Pilih Agama-</option>
                  {createSelectReligion()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>Pendidikan Terakhir <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="education_id3"
                  onChange={onDropdownSelected2}
                  value={state.education_id3}
                  required
                >
                  <option value="">-Pilih Pendidikan-</option>
                  {createSelectPendidikan()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Pekerjaan <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="occupation_id3"
                  onChange={onDropdownSelected2}
                  value={state.occupation_id3}
                  required
                >
                  <option value="">-Pilih Pekerjaan-</option>
                  {createSelectPekerjaan()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>
                  Penghasilan (isi hanya angka jangan diberi titik dan koma,
                  jika tidak ada diisi dengan angka 0)
                </label>
                <CurrencyFormat
                  name="salary3"
                  className="form-control"
                  onChange={onChangeNomSalary}
                  value={state.salary3 || ''}
                  maxLength="50"
                  thousandSeparator={true}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>No HP <span className="text-danger">*</span></label>
                <CurrencyFormat
                  className="form-control"
                  input="number"
                  name="hp3"
                  format="+62############"
                  onChange={onChange}
                  value={state.hp3 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Alamat Tempat Tinggal <span className="text-danger">*</span></label>
                <input
                  className="form-control"
                  type="text"
                  name="address3"
                  maxLength="200"
                  value={state.address3 || ''}
                  onChange={onChange}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Status Hubungan <span className="text-danger">*</span></label>
                <input
                  className="form-control"
                  type="text"
                  name="status_guardian"
                  maxLength="200"
                  value={state.status_guardian || ''}
                  onChange={onChange}
                  required
                />
              </div>
              <div className="form-group col-md-6">
              </div>
            </div>

          </div>
        )}
      </div>
    );
  }

  const createSelectPendidikan = () => {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_education_guardian;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  const createSelectPekerjaan = () => {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_occupation;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  const createSelectReligion = () => {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_religion;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  const createSelectClass = () => {
    // ini valuenya
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).classrooms;

    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].name}>
          {params[i].name}
        </option>
      );
    }

    return items;
  }

  const onChangeData = (e) => {
    var a = e.target.value;
    if (e.target.name.toLowerCase() == 'nik' || e.target.name.toLowerCase() == 'no_kk') {
      a = a.slice(0, 16);
    } else if (e.target.name.toLowerCase() == 'nisn') {
      a = a.slice(0, 10);
    }
    else {
      a = a.replace("/", "");
      a = a.replace('"', "");
    }
    state.data_list[e.target.id].value = a;
    changeState({});
    // this.forceUpdate();
  };

  const onChangeNomData = (e) => {
    // this.setState({ [e.target.name]: remSep(e.target.value) });
    state.data_list[e.target.id].value = remSep(e.target.value);
    changeState({});
    // this.forceUpdate();
  };

  const onChange = (e) => {
    var a = e.target.value;
    if (e.target.name.toLowerCase() === 'nik1' || e.target.name.toLowerCase() === 'nik2' || e.target.name.toLowerCase() === 'nik3') {
      a = a.slice(0, 16);
    } else {
      a = a.replace("/", "");
      a = a.replace('"', "");
    }
    changeState({ [e.target.name]: a });
  };
  const onChangeNomSalary = (e) => {
    changeState({ [e.target.name]: remSep(e.target.value) });
  }

  const createSelectItems = (item) => {
    const pdf_table = item.pdf_table;
    const test = "." + pdf_table;

    let items = [];
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER"))[pdf_table];

    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  const onDropdownSelected4 = (e) => {
    state.data_list[e.target.id].value = remSep(e.target.value);
    changeState({});
  };

  const onDropdownSelected2 = (e) => {
    changeState({ [e.target.name]: parseInt(e.target.value) });
  }

  const onDropdownSelected = (e) => {
    changeState({
      [e.target.name]: e.target.value.split("_")[0],
      [e.target.name + "_name"]: e.target.value.split("_")[1],
    });
  }

  const onDropdownSelected3 = (e) => {
    console.log(e.target);
    setState(prev => ({
      ...prev,
      parent_status_id: e.target.value || '',
    }));
  }

  const handleSubmit = (event) => {
    changeState({
      isLoading: true,
      data: state.data_list.filter((item) => item.value != null)
    });
    event.preventDefault();

    const listField = JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_ms_student;

    let valid = state.hp.substring(0, 2);

    if (valid != "08") {
      global.swal("Error", "Tolong masukan nomor Handphone yang valid");
      return;
    }
    let items = {};
    let item = [];

    // return;

    let items1 = Object.create({});
    items1.gender_id = 1;
    items1.guardian_id = state.guardian_id1;
    items1.fullname = state.fullname1;
    items1.nik = state.nik1;
    if (state.parent_status_id == "1" || state.parent_status_id == "2") {
      if ('nik1' in state && state.nik1.length != 16) {
        changeState({ isLoading: false });
        global.swal("Error", "NIK Ayah harus 16 digit");
        return false;
      }
    }
    items1.dob = state.dob1;
    items1.status_guardian_id = state.status_guardian_id1;
    items1.status_guardian = state.status_guardian ?? null;
    items1.education_id = state.education_id1;
    items1.occupation_id = state.occupation_id1;
    items1.salary = state.salary1 ? state.salary1 : 0;
    items1.hp = state.hp1;
    items1.address = state.address1;
    items1.religion_guardian_id = state.religion_guardian_id1;
    if (state.fullname1) {
      item.push(items1);
    }

    let items2 = Object.create({});
    items2.gender_id = 2;
    items2.guardian_id = state.guardian_id2;
    items2.fullname = state.fullname2;
    items2.nik = state.nik2;
    if (state.parent_status_id == "1" || state.parent_status_id == "3") {
      if ('nik2' in state && state.nik2.length != 16) {
        changeState({ isLoading: false });
        global.swal("Error", "NIK Ibu harus 16 digit");
        return false;
      }
    }
    items2.dob = state.dob2;
    items2.status_guardian_id = state.status_guardian_id2;
    items2.status_guardian = state.status_guardian ?? null;
    items2.education_id = state.education_id2;
    items2.occupation_id = state.occupation_id2;
    items2.salary = state.salary2 ? state.salary2 : 0;
    items2.hp = state.hp2;
    items2.address = state.address2;
    items2.religion_guardian_id = state.religion_guardian_id2;
    if (state.fullname2) {
      item.push(items2);
    }

    let items3 = Object.create({});
    items3.gender_id = state.gender_id3;
    items3.guardian_id = state.guardian_id3;
    items3.fullname = state.fullname3;
    items3.nik = state.nik3;
    if (state.parent_status_id === "4") {
      if ('nik3' in state && state.nik3.length !== 16) {
        changeState({ isLoading: false });
        global.swal("Error", "NIK Wali harus 16 digit");
        return false;
      }
    }
    items3.dob = state.dob3;
    items3.status_guardian_id = null;
    items3.education_id = state.education_id3;
    items3.occupation_id = state.occupation_id3;
    items3.salary = state.salary3 ? state.salary3 : 0;
    items3.hp = state.hp3;
    items3.address = state.address3;
    items3.religion_guardian_id = state.religion_guardian_id3;
    items3.status_guardian = state.status_guardian;
    if (state.fullname3) {
      item.push(items3);
    }
    localStorage.setItem("reg4", JSON.stringify(item));
    localStorage.setItem("statereg4", JSON.stringify(state));

    let data = (state.data_list.filter((item) => item.value != null));

    let data1 = [];
    items = Object.create({});
    items.field = "parent_status_id"
    items.value = state.parent_status_id;
    data1.push((items))
    if (state.both_parent_work) {
      items = Object.create({});
      items.field = "working_parent_id"
      items.value = state.both_parent_work;
      data1.push((items))
    }

    let dataConcat = data.concat(data1)

    let statereg4 = JSON.parse(localStorage.getItem("statereg4"));
    let reg4 = JSON.parse(localStorage.getItem("reg4"));

    const form_data = new FormData();
    form_data.append("registration_id", state.student_id);
    form_data.append("action", "next");
    form_data.append("data", JSON.stringify(dataConcat));
    form_data.append("guardian", JSON.stringify(reg4));
    form_data.append("sibling", JSON.stringify(state.sibling));
    form_data.append("achieve", JSON.stringify(state.achieve));
    for (var pair of form_data.entries()) {
      console.log(pair[0], pair[1]);
    }
    // return;
    trackPromise(
      fetch(global.variable.LINK_API + "flow/update", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        body: form_data,
      })
        .then((response) => response.json())
        .then((resData) => {
          changeState({ isLoading: false });
          if (resData.message === "Success") {
            global.swal(resData.message, "Berhasil Mendaftar", "success").then((value) => {
              localStorage.removeItem("statereg4");
              localStorage.removeItem("reg4");
              history.push("/uploaddokumendetail");
            });
          } else {
            global.swal.stopLoading();
            global.swal("Info", resData.message, "info");
          }
        })
    );
  };

  const handleAdd = () => {
    changeState(({
      sibling: [...state.sibling, { name: "", class: "0" }]
    }))
  };

  const handleClear = () => {
    // localStorage.removeItem("reg5");
    changeState({
      name: "",
      class: "",
      sibling: [],
    });
    // window.location.href = "/pendaftaran1";
  };

  const handleAddAchieve = () => {
    changeState(({
      achieve: [...state.achieve, { achieve_type_id: "0", name: "", achieve_rank_id: "0", promoter: "", rank: "" }]
    }))
  };

  const handleClearAchieve = () => {
    changeState({
      achieve_type_id: "",
      achieve_rank_id: "",
      name_achieve: "",
      promoter: "",
      rank: "",
      achieve: [],
    });
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="row">
          <div className="col-md-12">
            <div className="box">
              <div className="divContainter">
                <MDBCard className="mb-5">
                  <MDBCardBody>
                    <MDBBreadcrumb color="cyan lighten-4">
                      <MDBBreadcrumbItem active>
                        <a href="/home">Home</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem active>
                        Pengisian Data Calon Siswa
                      </MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                    {state.isLoading ? <LoadingSpinner /> : ""}
                    {showAlert &&
                      <div className="warning-alert">
                        <strong>Catatan Pengembalian</strong>
                        <br></br>
                        <p>{state.studentData.note_return}</p>
                      </div>}
                    <form onSubmit={handleSubmit}>
                      <div className="form-row">
                        {map(state.data_list, (item, idx) => {
                          return (
                            <div className="form-group col-md-6" key={idx}>
                              <label>
                                {item.name}
                                {item.is_required === 1 ? (<span className="text-danger"> *</span>) : ""}
                              </label>
                              {item.type === 1 ? (
                                <>
                                  {item.type_text === "nominal" ? (
                                    <CurrencyFormat
                                      required={item.is_required === 1}
                                      className="form-control"
                                      input="number"
                                      id={idx}
                                      name={item.field}
                                      onChange={onChangeNomData}
                                      value={item.value || ''}
                                      maxLength="25"
                                      thousandSeparator={true}
                                      style={{ width: "100%" }}
                                    />
                                  ) : (
                                    <>
                                      {item.field === 'hp' ? (
                                        <input
                                          className="form-control"
                                          type={item.type_text}
                                          name={item.field}
                                          id={idx}
                                          maxLength="100"
                                          disabled
                                          // defaultValue={state.data_list[idx].value || ''}
                                          onChange={onChangeData}
                                          required={item.is_required === 1}
                                          value={item.value}
                                        />
                                      ) : (
                                        <input
                                          className="form-control"
                                          type={item.type_text}
                                          name={item.field}
                                          id={idx}
                                          maxLength="100"
                                          value={item.value || ''}
                                          onChange={onChangeData}
                                          required={item.is_required === 1}
                                        />
                                      )}
                                    </>

                                  )}
                                </>
                              ) : (
                                <>
                                  <select
                                    required={
                                      item.is_required === 1 ? true : false
                                    }
                                    id={idx}
                                    className="form-control"
                                    name={item.field}
                                    onChange={onDropdownSelected4.bind(this)}
                                    value={item.value || ''}
                                  >
                                    <option value=""> - Pilih {item.name} - </option>
                                    {createSelectItems(item)}
                                  </select>
                                </>
                              )}
                            </div>
                          );
                        })}
                        <br></br>
                      </div>
                      <hr></hr>
                      <h5 style={{ textDecorationLine: "underline" }}>
                        Data Orangtua
                      </h5>
                      {formGuardian()}
                      <div>
                        <label
                          style={{
                            fontSize: 20,
                            textDecorationLine: "underline",
                          }}
                        >
                          Saudara yang masih bersekolah di{" "}
                          {
                            JSON.parse(localStorage.getItem("DATA_PARAMETER"))
                              .prm_unit.name
                          }
                        </label>
                        {map(state.sibling, (datax, idx) => (
                          <React.Fragment key={idx}>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Nama Siswa</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  onChange={e => onChangeData2(e, idx)}
                                  maxLength="200"
                                  value={datax.name}
                                  style={{ width: "100%" }}
                                // required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Kelas</label>
                                <select
                                  className="form-control"
                                  name="class"
                                  onChange={e => onDropdownSelectedSibling(e, idx)}
                                  value={datax.class}
                                >
                                  <option value="0">-Pilih Kelas-</option>
                                  {createSelectClass()}
                                </select>
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                        <div className="row">
                          <div className="col-12 mb-2">
                            <MDBBtn
                              onClick={handleAdd}
                              color="primary"
                              block
                              style={{ height: 50 }}
                            >
                              Tambah Saudara
                            </MDBBtn>
                          </div>
                          <div className="col-12">
                            {showRemove()}
                          </div>
                        </div>
                        {showPrestasi()}
                      </div>

                      <div>
                        <br></br>
                        <label
                          style={{
                            fontSize: 20,
                            textDecorationLine: "underline",
                          }}
                        >
                          Prestasi
                        </label>
                        {map(state.achieve, (datax, idx) => (
                          <React.Fragment key={idx}>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Jenis</label>
                                <select
                                  className="form-control"
                                  name="achieve_type_id"
                                  onChange={e => onDropdownSelectedAchieve(e, idx)}
                                  value={datax.achieve_type_id}
                                // required
                                >
                                  <option value="0">-Pilih Jenis-</option>
                                  {createSelectAchieveType()}
                                </select>
                              </div>
                              <div className="form-group col-md-6">
                                <label>Nama Prestasi</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  onChange={e => onChangeDataAchieve(e, idx)}
                                  maxLength="200"
                                  value={datax.name}
                                  style={{ width: "100%" }}
                                // required
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Penyelenggara</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="promoter"
                                  onChange={e => onChangeDataAchieve(e, idx)}
                                  maxLength="200"
                                  value={datax.promoter}
                                  style={{ width: "100%" }}
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Tingkat</label>
                                <select
                                  className="form-control"
                                  name="achieve_rank_id"
                                  onChange={e => onDropdownSelectedAchieve(e, idx)}
                                  value={datax.achieve_rank_id}
                                // required
                                >
                                  <option value="0">-Pilih Tingkat-</option>
                                  {createSelectAchieveRank()}
                                </select>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Peringkat</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="rank"
                                  maxLength="5"
                                  value={datax.rank}
                                  onChange={e => onChangeDataAchieve(e, idx)}
                                  style={{ width: "100%" }}
                                // required
                                />
                              </div>
                              <div className="form-group col-md-6"></div>
                            </div>
                          </React.Fragment>
                        ))

                        }

                        <div className="row">
                          <div className="col-12 mb-2">
                            <MDBBtn
                              color="primary"
                              onClick={handleAddAchieve}
                              block
                              style={{ height: 50 }}
                            >
                              Tambah Prestasi
                            </MDBBtn>
                          </div>
                          <div className="col-12">
                            {showRemove2()}
                          </div>
                        </div>
                        <br></br>

                        {showPrestasi2()}
                      </div>
                      <MDBBtn color="primary" disabled={state.isLoading} block type="submit">
                        {
                          state.isLoading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          ) : 'Lanjut Upload Dokumen'
                        }
                      </MDBBtn>
                    </form>
                  </MDBCardBody>
                </MDBCard>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Pendaftaran;
