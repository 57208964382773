import React, { Component } from 'react';
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSwitch,
  MDBFileInput,
  MDBRangeInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBLink,
  MDBBtn,
} from "mdbreact";

class Upload_dokumen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      CB_REGTYPE: '',
      CB_STUDYYEAR: '',
      CB_JALUR: '',
      CB_FLOW: '',
      CB_STATUS: '',
      CB_METHOD: '',
      val_jalur: '',
      val_studyyear: '',
      val_method: '',
      is_show: false,
    }
  }

  componentDidMount() {
    /*fetch(global.variable.LINK_API + 'student/flow/3',
    {
      method: 'get',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data)
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

        this.setState({CB_JALUR : resData.data});

      }
      console.log(resData);
       
    })*/

    fetch(global.variable.LINK_API + 'parameter',
      {
        method: 'get',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        //body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          this.setState({ CB_REGTYPE: resData.data.prmRegistrationType, CB_FLOW: resData.data.prmFlow, CB_STATUS: resData.data.prmInterviewStatus, CB_STUDYYEAR: resData.data.prmStudyYear, CB_METHOD: resData.data.prmInterviewMethod });
          localStorage.setItem('DATA_PARAMETER', JSON.stringify(resData.data));
        }
        console.log(JSON.parse(localStorage.getItem('DATA_PARAMETER')));

      })
  }

  createSelectItems() {
    let items = [];
    for (let i = 0; i < this.state.CB_JALUR.length; i++) {
      items.push(<option key={i} value={this.state.CB_JALUR[i].id + '-' + this.state.CB_JALUR[i].fullname}>{this.state.CB_JALUR[i].id + ' - ' + this.state.CB_JALUR[i].fullname}</option>);
    }
    return items;
  }

  createSelectItemsStudyYear() {
    let items = [];
    for (let i = 0; i < this.state.CB_STUDYYEAR.length; i++) {
      items.push(<option key={i} value={this.state.CB_STUDYYEAR[i].id}>{this.state.CB_STUDYYEAR[i].name}</option>);
    }
    return items;
  }

  createSelectItemsREG() {
    let items = [];
    for (let i = 0; i < this.state.CB_REGTYPE.length; i++) {
      items.push(<option key={i} value={this.state.CB_REGTYPE[i].id}>{this.state.CB_REGTYPE[i].id + ' - ' + this.state.CB_REGTYPE[i].name}</option>);
    }
    return items;
  }

  createSelectItemsFlow() {
    let items = [];
    for (let i = 0; i < this.state.CB_FLOW.length; i++) {
      items.push(<option key={i} value={this.state.CB_FLOW[i].id}>{this.state.CB_FLOW[i].name}</option>);
    }
    return items;
  }

  createSelectItemsstatus() {
    let items = [];
    for (let i = 0; i < this.state.CB_STATUS.length; i++) {
      items.push(<option key={i} value={this.state.CB_STATUS[i].id}>{this.state.CB_STATUS[i].name}</option>);
    }
    return items;
  }

  createSelectItemsmethod() {
    let items = [];
    for (let i = 0; i < this.state.CB_METHOD.length; i++) {
      items.push(<option key={i} value={this.state.CB_METHOD[i].id}>{this.state.CB_METHOD[i].name}</option>);
    }
    return items;
  }

  onDropdownSelected(e) {
    console.log([e.target.name] + ' - ' + e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  }

  onDropdownSelectedJalur(e) {
    if (e.target.value === "") {
      this.setState({ is_show: false });
    } else {

      fetch(global.variable.LINK_API + 'student/flow/5?registration_type_id=' + e.target.value,
        {
          method: 'get',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + global.cookies.get('access_token'),
          },
          //body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(resData => {
          if (resData.message === "Success") {
            //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

            this.setState({ CB_JALUR: resData.data, is_show: true });

          }
          console.log(resData);

        })
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    let jalur = this.state.val_regtype ? this.state.val_regtype : '';
    let flow = this.state.val_flow ? this.state.val_flow : '';
    let status = this.state.val_status ? this.state.val_status : '';
    let method = this.state.val_method ? this.state.val_method : '';

    console.log(jalur)

    /*localStorage.setItem('DATA_JALUR', this.state.val_jalur);*/

    const data = { registration_type_id: jalur, flow_id: flow, interview_status_id: status, study_year: this.state.val_studyyear, interview_method_id: method }

    fetch(global.variable.LINK_API + 'report/interview',
      {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        body: JSON.stringify(data),
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          localStorage.setItem('data_report', JSON.stringify(resData.data));
          window.location.href = "/reportjadwalwawancaradetail";
        } else {
          global.swal("Error", resData.error, "info")
        }

      })

  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">

                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem href>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Report Jadwal Wawancara
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>

                        <div className='form-group'>
                          <label htmlFor="exampleForm2">Pilih Tahun</label>
                          <select
                            className="form-control" as="select" name="val_studyyear" onChange={this.onDropdownSelected.bind(this)}>
                            <option value="">- Semua Tahun -</option>
                            {this.createSelectItemsStudyYear()}

                          </select>
                        </div>

                        <div className='form-group'>
                          <label htmlFor="exampleForm2">Pilih Jalur</label>
                          <select
                            className="form-control" as="select" name="val_regtype" onChange={this.onDropdownSelected.bind(this)}>
                            <option value="">- Semua Jalur -</option>
                            {this.createSelectItemsREG()}

                          </select>
                        </div>

                        {/* <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Pilih Status</Form.Label>
                            <Form.Control as="select" name="val_flow" onChange={this.onDropdownSelected.bind(this)}>
                              <option value="">- Semua Status -</option>
                              {this.createSelectItemsFlow()}
                              
                            </Form.Control>
                          </Form.Group> */}

                        {/* <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Pilih Status Wawancara</Form.Label>
                            <Form.Control as="select" name="val_status" onChange={this.onDropdownSelected.bind(this)}>
                              <option value="">- Semua Status Wawancara -</option>
                              {this.createSelectItemsstatus()}
                              
                            </Form.Control>
                          </Form.Group> */}

                        <div className='form-group'>
                          <label htmlFor="exampleForm2">Pilih Metode Wawancara</label>
                          <select
                            className="form-control" as="select" name="val_method" onChange={this.onDropdownSelected.bind(this)}>
                            <option value="">- Semua Status Wawancara -</option>
                            {this.createSelectItemsmethod()}

                          </select>
                        </div>

                        <MDBBtn
                          color="primary"
                          block
                          type="submit">
                          Submit
                        </MDBBtn>




                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>


              </div>
            </div>
          </div>
        </section>
      </div>

    );
  }
}

export default Upload_dokumen;