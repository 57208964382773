import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
} from "mdbreact";
import Select from "react-select";
// import Swal from "sweetalert2";

class Wawancara extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CB_REGTYPE: '',
      CB_JALUR: '',
      val_jalur: '',
      is_show: false,
      valueOption: {},
    };
  }

  componentDidMount() {

    // fetch(global.variable.LINK_API + 'auth/parameter',
    // {
    //   method: 'get',
    //   headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       'Authorization': 'Bearer '+ global.cookies.get('access_token'),
    //   },
    //   //body: JSON.stringify(data)
    // })
    // .then(response =>  response.json())
    // .then(resData => {
    //   if(resData.message==="Success"){
    //     this.setState({CB_REGTYPE : resData.data.prmRegistrationType});
    //     localStorage.setItem('DATA_PARAMETER', JSON.stringify(resData.data));
    //   }
    //   console.log(JSON.parse(localStorage.getItem('DATA_PARAMETER')));

    // })
    fetch(global.variable.LINK_API + "flow/liststudent?flow=INT", {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      //body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

          this.setState({ CB_JALUR: resData.data });
        }
      });

    fetch(global.variable.LINK_API + 'auth/parameter', {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      //body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          localStorage.setItem("DATA_PARAMETER", JSON.stringify(resData.data));
        }
        // console.log(JSON.parse(localStorage.getItem("DATA_PARAMETER")));
      });
  }

  createSelectItems() {
    let items = [];
    for (let i = 0; i < this.state.CB_JALUR.length; i++) {
      items.push(
        // <option
        //   key={i}
        //   value={
        //     this.state.CB_JALUR[i].registration_id + "-" + this.state.CB_JALUR[i].fullname
        //   }
        // >
        {
          value: this.state.CB_JALUR[i].registration_id + " - " + this.state.CB_JALUR[i].fullname,
          label: this.state.CB_JALUR[i].registration_id + " - " + this.state.CB_JALUR[i].fullname
        }
        // </option> 
      );
    }
    return <Select options={items} onChange={this.onDropdownSelected2.bind(this)} placeholder="- Pilih Siswa -" />;
  }

  onDropdownSelected2(e) {
    this.setState({ valueOption: e.value });
  }

  createSelectItemsREG() {
    let items = [];
    for (let i = 0; i < this.state.CB_REGTYPE.length; i++) {
      items.push(<option key={i} value={this.state.CB_REGTYPE[i].id}>{this.state.CB_REGTYPE[i].id + ' - ' + this.state.CB_REGTYPE[i].name}</option>);
    }
    return items;
  }

  onDropdownSelected(e) {
    // console.log([e.target.name] + ' - ' +  e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  }

  onDropdownSelectedJalur(e) {
    if (e.target.value === "") {
      this.setState({ is_show: false });
    } else {

      fetch(global.variable.LINK_API + 'student/flow/5?currentyear=1&is_offline=ALL&registration_type_id=' + e.target.value,
        {
          method: 'get',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + global.cookies.get('access_token'),
          },
          //body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(resData => {
          if (resData.message === "Success") {
            //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

            this.setState({ CB_JALUR: resData.data, is_show: true });

          }
          console.log(resData);

        })
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    const student_id = Object.keys(this.state.valueOption).length === 0 ? '' : this.state.valueOption.split("-")[0];
    const findIdx = this.state.CB_JALUR.findIndex((i) => i.registration_id === student_id.trim());
    localStorage.setItem("DATA_JALUR", this.state.valueOption);
    localStorage.setItem("STUDENT_DETAIL", JSON.stringify(this.state.CB_JALUR[findIdx]));
    localStorage.setItem("IS_OFFLINE", 0);

    if (Object.keys(this.state.valueOption).length === 0) {
      global.swal("Info", "Harap pilih siswa", "info");
    } else {
      this.props.history.push("/wawancaradetail");
    }
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Wawancara
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="exampleForm2">Pilih Siswa</label>
                          {/* <select
                              className="form-control"
                              as="select"
                              name="val_jalur"
                              onChange={this.onDropdownSelected.bind(this)}
                            >
                              <option value="">- Pilih Siswa -</option> */}
                          {this.state.CB_JALUR ? this.createSelectItems() : ''}
                          {/* </select> */}
                          <br></br>
                          <MDBBtn color="primary" block type="submit">
                            lanjut
                          </MDBBtn>
                        </div>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Wawancara;
